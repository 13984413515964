import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { MenuItem, Button } from '@material-ui/core';
import * as yup from 'yup';
import Placeholder from '../common/Placeholder';
import CreateTaskDialog from './CreateTaskDialog';
import { showErrorSnackbar } from '../../redux/actions/appActions';
import {
  ENTITY_TYPE_FILE,
  TASK_CONVERTER_SDL_EXPORT,
  TASK_CONVERTER_SDL_IMPORT,
} from '../../core/entities';
import {
  fetchSdlPing,
  createSdlImportTask,
  createSdlExportTask,
  fetchSdlFolderLocation,
} from '../../redux/actions/taskActions';
import { isObjectEmpty } from '../../core/services/commonService';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { checkFileExist, getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { SDL_ALLOWED_IMAGE_RESOLUTIONS, SDL_ALLOWED_LANGUAGES, TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';
import SdlFoldersDialog from './SdlFoldersDialog';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

const TYPES = {
  Export: TASK_CONVERTER_SDL_EXPORT,
  Import: TASK_CONVERTER_SDL_IMPORT,
};

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

const validationSchema = taskType => {
  let schema = {};

  if (taskType === TASK_CONVERTER_SDL_IMPORT) {
    const taskImportSchema = {
      sourceFilePath: yup.string()
        .required('Source file path is required')
        .test(
          'start_end_with_space',
          'The element of the source file path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Source file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const { sourceFilePathSearchValue } = this.parent;
          const value = `${v}/${sourceFilePathSearchValue}`;

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Source file name cannot contain the new lines',
          function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            return value && !value.includes('\n');
          },
        )
        .test(
          'file-exist',
          'Source file does not exist or is not a file entity',
          async function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            if (value) {
              const response = await getPathEntity(value);

              return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
            }

            return false;
          },
        ),
    };

    schema = Object.assign(schema, taskImportSchema);
  }

  if (taskType === TASK_CONVERTER_SDL_EXPORT) {
    const taskExportSchema = {
      outputFilename: yup.string()
        .required('Output file name is required')
        .test(
          'start_end_with_space',
          'The output filename can not start or end with spaces',
          v => !(v.startsWith(' ') || v.endsWith(' ')),
        )
        .test(
          'allowed-characters',
        <>
          Output file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
        )
        .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
        .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
        .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
        .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
      outputFolderPath: yup.string()
        .test(
          'required',
          'Output folder path is required',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && value !== '';
          },
        )
        .test(
          'start_end_with_space',
          'The element of the output folder path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Output folder can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Output folder name cannot contain the new lines',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('\n');
          },
        )
        .test(
          'not-contain-extension',
          'Output folder name cannot contain the extensions',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('.zip');
          },
        )
        .test(
          'folder-exist',
          'Output folder does not exist or is not a folder entity',
          async function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            if (value) {
              const parentResponse = await getPathEntity(value.split('/')[0]);
              if (!(
                parentResponse.status === 200
                  && parentResponse.data.data.type !== ENTITY_TYPE_FILE
              )
              ) {
                return this.createError({ message: 'Output parent folder does not exist' });
              }

              const valueResponse = await getPathEntity(value);

              return (
                valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE
              );
            }

            return false;
          },
        ),
    };

    schema = Object.assign(schema, taskExportSchema);
  }

  return yup.object(schema);
};

const generateInitValues = task => {
  const config = JSON.parse(task.config || '{}');

  const [sourceFilePath, sourceFilename] = parsePath(task.source || '');
  const [outputFolderPath, outputFilename] = parsePath(task.output || '');

  return {
    password: '',
    displayValue: '',
    domain: config.domain || '',
    version: config.version || '',
    server: config.server_ip || '',
    username: config.username || '',
    folderRef: config.folder_ref || '',
    language: config.language || 'en-US',

    // sdl import
    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],

    // sdl export
    outputFilename: outputFilename || '',
    asyncLoading: config.async_loading || false,
    fixReferences: config.fix_references || false,
    imageResolution: config.image_resolution || '',
    useTitleInFilename: config.use_title_in_filename || false,

    outputFolderPath,
    outputFolderPathObjects: [],
    outputFolderPathSearchValue: '',

    createFolders: false,
  };
};

function CreateSdlDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [creatingTask, setCreatingTask] = useState(false);

  const [taskType, setTaskType] = useState(rerunTask.converter || '');

  const [remoteFolderDialogOpen, setRemoteFolderDialogOpen] = useState(false);

  const [outputFileWarning, setOutputFileWarning] = useState(undefined);

  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);
  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);

  const [firstStepError, setFirstStepError] = useState('');
  const [enabledService, setEnabledService] = useState(false);

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema: validationSchema(taskType),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const onFormSubmit = values => {
    setCreatingTask(true);

    if (taskType === TASK_CONVERTER_SDL_IMPORT) {
      const task = {
        source: `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`,
        domain: values.domain !== '' ? values.domain : undefined,
        folder_ref: values.folderRef,
        language: values.language,
        username: values.username,
        password: values.password,
        server_ip: values.server,
        version: values.version,
      };

      dispatch(createSdlImportTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }

    if (taskType === TASK_CONVERTER_SDL_EXPORT) {
      let outputFolder = `${values.outputFolderPath}${values.outputFolderPath.endsWith('/') ? '' : '/'}`;
      if (values.outputFolderPathSearchValue !== '') {
        outputFolder += `${values.outputFolderPathSearchValue}${values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
      }

      const task = {
        output: `${outputFolder}${values.outputFilename}`,
        domain: values.domain !== '' ? values.domain : undefined,
        use_title_in_filename: values.useTitleInFilename,
        image_resolution: values.imageResolution,
        fix_references: values.fixReferences,
        async_loading: values.asyncLoading,
        folder_ref: values.folderRef,
        username: values.username,
        password: values.password,
        language: values.language,
        server_ip: values.server,
        version: values.version,
      };

      dispatch(createSdlExportTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_SDL_EXPORT) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [taskType, enabledService, formik.values.outputFolderPath]);

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_SDL_IMPORT) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [taskType, enabledService, formik.values.sourceFilePath]);

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  const config = {
    title: 'Create Tridion Sdl',
    steps: [
      {
        isValid: formik.values.username !== '' && formik.values.password !== ''
          && formik.values.server !== '' && taskType !== '',
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'General settings',
        fields: [
          {
            tooltip: 'The server URL or IP address.',
            onChange: e => formik.handleChange(e),
            value: formik.values.server,
            error: formik.errors.server,
            placeholder: 'Server',
            label: 'Server',
            key: 'server',
          },
          {
            tooltip: 'It is an optional field. If your server is accessible by configuration via /etc/hosts/, you need to enter the domain name here.',
            onChange: e => formik.handleChange(e),
            value: formik.values.domain,
            error: formik.errors.domain,
            placeholder: 'Domain',
            label: 'Domain',
            key: 'domain',
          },
          {
            onChange: e => formik.handleChange(e),
            tooltip: 'The name of the user.',
            value: formik.values.username,
            error: formik.errors.username,
            placeholder: 'Username',
            label: 'Username',
            key: 'username',
          },
          {
            onChange: e => formik.handleChange(e),
            tooltip: 'Password for the user.',
            value: formik.values.password,
            error: formik.errors.password,
            placeholder: 'Password',
            label: 'Password',
            type: 'password',
            key: 'password',
          },
          {
            item: ([key, value]) => <MenuItem key={value} value={value}>{key}</MenuItem>,
            renderValue: selected => {
              if (selected === '') return <Placeholder>Task type</Placeholder>;

              let returnValue = selected;
              Object.entries(TYPES).forEach(([key, value]) => {
                if (selected === value) returnValue = key;
              });

              return returnValue;
            },
            onChange: event => setTaskType(event.target.value),
            items: Object.entries(TYPES),
            label: 'Task type',
            error: undefined,
            value: taskType,
            key: 'taskType',
          },
        ],
        async onSubmit() {
          setFirstStepError('');
          setEnabledService(false);

          const value = await fetchSdlPing(
            formik.values.server,
            formik.values.username,
            formik.values.password,
            formik.values.domain !== '' ? formik.values.domain : undefined,
          );

          if (value) setEnabledService(true);
          else setFirstStepError('Unable to connect to the server. Please check if server is running and credentials are correct.');

          return value;
        },
        allowContinue: taskType !== '',
        error: firstStepError,
        loading: false,
      },
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Task details',
        fields: [],
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    loading: creatingTask,
    initActiveStep: 0,
  };

  const fields = [
    {
      onChange: e => formik.handleChange(e),
      tooltip: 'The version of the objects.',
      value: formik.values.version,
      error: formik.errors.version,
      placeholder: 'Version',
      label: 'Version',
      key: 'version',
    },
    {
      item: lang => <MenuItem key={lang} value={lang}>{lang}</MenuItem>,
      tooltip: 'The (working) language of the object.',
      onChange: e => formik.handleChange(e),
      value: formik.values.language,
      error: formik.errors.language,
      items: SDL_ALLOWED_LANGUAGES,
      label: 'Language',
      key: 'language',
    },
    {
      tooltip: 'The folder path on the Tridion sdl repository.',
      onChange: e => formik.handleChange(e),
      value: formik.values.displayValue,
      error: formik.errors.displayValue,
      placeholder: 'Remote folder',
      label: 'Remote folder',
      key: 'displayValue',
      button: (
        <Button
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          onClick={() => setRemoteFolderDialogOpen(true)}
          variant="contained"
          className="button"
          color="primary"
        >
          Browse
        </Button>
      ),
      type: 'file',
    },
  ];

  if (taskType === TASK_CONVERTER_SDL_IMPORT) {
    config.steps[1] = {
      type: STEP_FIELD_TYPE_MULTIPLE,
      title: 'Task details',
      fields,
      isValid: formik.values.version !== '' && formik.values.displayValue !== '',
      onSubmit: () => Promise.resolve(true),
      allowContinue: true,
      loading: false,
    };

    config.steps.push({
      title: 'Source file path',
      type: STEP_FIELD_TYPE_PATH,
      pathField: {
        objects: filterObjects(
          formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
        ),
        onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
        value: formik.values.sourceFilePath !== ''
          ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
          : formik.values.sourceFilePathSearchValue,
        error: formik.errors.sourceFilePath,
        loading: false,
      },
      allowContinue: formik.values.sourceFilePath !== '',
      isValid: formik.errors.sourceFilePath === undefined,
      onSubmit: () => Promise.resolve(true),
      loading: loadingSourceFileObjects,
    });
  }

  if (taskType === TASK_CONVERTER_SDL_EXPORT) {
    let allowCreateFolders = false;
    if (formik.errors.outputFolderPath !== undefined) {
      if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
        allowCreateFolders = true;
      }
    }

    fields.push({
      renderValue: selected => {
        if (!selected || selected === '') return <Placeholder>Image resolution</Placeholder>;

        return selected;
      },
      tooltip: 'The resolution of the object. Will be used only for illustrations.',
      item: res => <MenuItem key={res} value={res}>{res}</MenuItem>,
      onChange: e => formik.handleChange(e),
      value: formik.values.imageResolution,
      error: formik.errors.imageResolution,
      items: SDL_ALLOWED_IMAGE_RESOLUTIONS,
      label: 'Image resolution',
      key: 'imageResolution',
    });

    config.steps[1] = {
      type: STEP_FIELD_TYPE_MULTIPLE,
      title: 'Task details',
      fields,
      isValid: formik.values.version !== '' && formik.values.displayValue !== ''
        && formik.values.username !== '' && formik.values.password !== '',
      onSubmit: () => Promise.resolve(true),
      configurations: [
        {
          tooltip: 'The title of the xml files will be used in the filenames.',
          onChange: event => formik.handleChange(event),
          value: formik.values.useTitleInFilename,
          label: 'Use title in filename',
          key: 'useTitleInFilename',
        },
        {
          tooltip: 'The references in the output files will be fixed.',
          onChange: event => formik.handleChange(event),
          value: formik.values.fixReferences,
          label: 'Fix references',
          key: 'fixReferences',
        },
        {
          tooltip: 'The files will be loaded in the few processes.',
          onChange: event => formik.handleChange(event),
          value: formik.values.asyncLoading,
          label: 'Async loading',
          key: 'asyncLoading',
        },
      ],
      allowContinue: true,
      loading: false,
    };

    config.steps.push({
      title: 'Output file path',
      type: STEP_FIELD_TYPE_PATH,
      fields: [
        {
          onChange: event => formik.handleChange(event),
          value: formik.values.outputFilename,
          error: formik.errors.outputFilename,
          placeholder: 'Example.zip',
          label: 'Output file name',
          key: 'outputFilename',
        },
      ],
      pathField: {
        label: 'Output folder path',
        placeholder: 'Search a folder by name',
        objects: filterObjects(
          formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
        ).filter(o => o.type === 'folder'),
        onChange: value => {
          onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
          formik.setFieldValue('createFolders', false);
        },
        value: formik.values.outputFolderPath !== ''
          ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
          : formik.values.outputFolderPathSearchValue,
      },
      configurations: allowCreateFolders ? [
        {
          tooltip: (
            <p>
              <p>
                The output folder does not exist. If you select this parameter,
                the folder will be created automatically.
              </p>
              <p>
                <b>Notice. </b>
                Use this parameter carefully.
              </p>
              <p>
                THE PARENT FOLDER MUST EXIST.
              </p>
            </p>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.createFolders,
          label: 'Create Folders',
          key: 'createFolders',
        },
      ] : [],
      allowContinue: formik.values.outputFolderPath !== '' && formik.values.outputFilename !== ''
        && (formik.errors.outputFolderPath === undefined || formik.values.createFolders),
      isValid: formik.values.outputFolderPath !== ''
        && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
      onSubmit: () => Promise.resolve(true),
      loading: creatingTask || loadingOutputFolderObjects,
      error: formik.errors.outputFolderPath,
      warning: outputFileWarning,
    });
  }

  useEffect(() => {
    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename]);

  const handleRemoteFolderChange = folderRef => {
    formik.setFieldValue('folderRef', Number(folderRef));

    dispatch(fetchSdlFolderLocation(
      formik.values.server,
      formik.values.username,
      formik.values.password,
      formik.values.domain !== '' ? formik.values.domain : undefined,
      folderRef,
    ))
      .then(location => {
        if (!location) {
          dispatch(showErrorSnackbar('Can not load the folder location'));
          return;
        }

        formik.setFieldValue('displayValue', location);
      });
  };

  useEffect(() => {
    if (enabledService && rerunTask && !isObjectEmpty(rerunTask)) {
      const taskConfig = JSON.parse(rerunTask.config || '{}');

      handleRemoteFolderChange(taskConfig.folder_ref);
    }
  }, [enabledService]);

  return (
    <>
      <CreateTaskDialog
        open={open}
        config={config}
        onClose={() => {
          onClose();

          if (resetOnClose) {
            setTaskType('');
            setFirstStepError(undefined);
            formik.setValues(generateInitValues({}));
          }
        }}
        resetOnClose={resetOnClose}
      />

      <SdlFoldersDialog
        username={formik.values.username}
        password={formik.values.password}
        server={formik.values.server}
        domain={formik.values.domain}
        onClose={() => setRemoteFolderDialogOpen(false)}
        onSubmit={val => handleRemoteFolderChange(val)}
        open={remoteFolderDialogOpen}
        title="Remote folder"
      />
    </>
  );
}

CreateSdlDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateSdlDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateSdlDialog;
