import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Button,
  Snackbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import { FiLink2 } from 'react-icons/fi';
import { AiOutlineMenu } from 'react-icons/ai';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { IoMdRefresh } from 'react-icons/io';
import { getTask } from '../../redux/actions/taskActions';
import { MENU_CLOSE, MENU_OPEN } from '../../redux/actionTypes';
import Loader from '../common/Loader';
import NotFound from '../common/NotFound';
import { TASKS_ROUTE } from '../../core/constants';
import { TASK_STATUS_FINISHED } from '../../core/entities';
import { isObjectEmpty } from '../../core/services/commonService';
import TaskDetailsInfo from './TaskDetailsInfo';
import TaskDetailsLogs from './TaskDetailsLogs';
import TaskDetailsReports from './TaskDetailsReports';
import TaskDetailsReportLogs from './TaskDetailsReportLogs';
import HashedTabs from '../common/HashedTabs';
import { MAIN_UI_COLOR } from '../../config';

function getTaskReportConfig(task) {
  let reports = [];

  if (['elements-information-report', 'count-of-docx-pages-report', 'not-referenced-ids-report'].includes(task.converter)) {
    reports = [
      {
        name: 'general',
        label: 'General Report',
      },
    ];
  }

  if (['conref-targets-report', 'ph-id-content-report', 'info-types-report'].includes(task.converter)) {
    reports = [
      {
        name: 'general',
        label: 'General Report',
      },
      {
        name: 'detailed',
        label: 'Detailed Report',
      },
    ];
  }

  if (task.converter === 'reuse-report') {
    reports = [
      {
        name: 'reuse_mechanism_counts',
        label: 'Reuse Mechanism Counts',
      },
      {
        name: 'reuse_mechanism_files',
        label: 'Reuse Mechanism Files',
      },
      {
        name: 'reuse_mechanism_summary',
        label: 'Reuse Mechanism Summary',
      },
      {
        name: 'reuse_report_exact_matches',
        label: 'Reuse Exact Matches',
      },
      {
        name: 'reuse_report_fuzzy_matches',
        label: 'Reuse Fuzzy Matches',
      },
      {
        name: 'reuse_report_matching_summary',
        label: 'Reuse Matching Summary',
      },
      {
        name: 'reuse_report_processing_summary',
        label: 'Reuse Processing Summary',
      },
    ];
  }

  if (task.converter === 'required-cleanup-report') {
    reports.push({
      name: 'required-cleanup',
      label: '\'required-cleanup\' report',
    });
  }

  if (task.converter === 'ixiasoft-export-validator') {
    reports.push({
      name: 'versions_summary',
      label: 'Versions',
    });

    reports.push({
      name: 'errors_summary',
      label: 'Errors',
    });
  }

  if (task.converter === 'root-maps-report') {
    reports.push({
      name: 'root_maps_report',
      label: 'Root Maps Report',
    });
  }

  if (task.expectedResult) {
    reports.push({
      name: 'diff',
      label: 'Difference',
    });
  }

  if (reports.length === 0) {
    return {};
  }

  return {
    default: reports[0].name,
    reports,
  };
}

function TaskDetails() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [task, setTask] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMenuOpen = useSelector(state => state.app.isMenuOpen);
  const isMobile = useSelector(state => state.app.isMobile);

  const isReportsTabOn = !isObjectEmpty(task) && task.converter === 'evaluation' && task.status === TASK_STATUS_FINISHED;

  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  useEffect(() => {
    let timer;

    getTask(id)
      .then(taskEntity => {
        if (!taskEntity) setError(true);
        else {
          setTask(taskEntity);

          timer = setInterval(() => {
            getTask(id)
              .then(newTask => {
                if (JSON.stringify(task) !== JSON.stringify(newTask)) {
                  setTask(newTask);
                }

                if (newTask.status === 'FINISHED') {
                  clearInterval(timer);
                }
              });
          }, 3000);
        }
      })
      .catch(() => setError(true));

    return () => timer && clearInterval(timer);
  }, [id]);

  const onRefresh = () => {
    setLoading(true);

    getTask(id)
      .then(taskEntity => {
        if (taskEntity) setTask(taskEntity);
        else setError(true);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => onRefresh(), [id]);

  if (error) {
    return <NotFound />;
  }

  if (loading || isObjectEmpty(task)) {
    return <Loader />;
  }

  const tabs = [
    { key: 'details', label: 'Details', component: <TaskDetailsInfo task={task} /> },
    { key: 'logs', label: 'Logs', component: <TaskDetailsLogs task={task} /> },
  ];

  if (isReportsTabOn) {
    tabs.push({ key: 'evaluation-report', label: 'Evaluation Report', component: <TaskDetailsReports task={task} /> });
  }

  const taskReportLogs = getTaskReportConfig(task);

  if (task.status === TASK_STATUS_FINISHED && !isObjectEmpty(taskReportLogs)) {
    tabs.push({ key: 'report-logs', label: 'Report', component: <TaskDetailsReportLogs task={task} config={taskReportLogs} /> });
  }

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopySnackbarOpen(true);
  };

  return (
    <>
      <div style={{
        paddingBottom: '30px',
        paddingRight: '60px',
        paddingLeft: '60px',
        paddingTop: '48px',
        background: 'white',
      }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              variant="h4"
              style={{
                alignItems: 'center',
                display: 'flex',
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {(!isMenuOpen || isMobile) && (
              <IconButton
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
                onClick={() => dispatch({ type: !isMenuOpen ? MENU_OPEN : MENU_CLOSE })}
              >
                <AiOutlineMenu />
              </IconButton>
              )}

              <IconButton
                onClick={() => history.push(TASKS_ROUTE)}
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
              >
                <HiOutlineArrowLeft />
              </IconButton>

              <span style={{ fontSize: '28px' }}>
                {'Task UUID - '}
                {window.location.pathname.split('/').slice(-1).pop()}
              </span>
            </Typography>
          </Grid>

          <Grid className="buttons-container" container item xs={12} sm={12} md={4} justifyContent="flex-end">
            <Button
              onClick={handleCopyButtonClick}
              variant="outlined"
              className="button"
              color="primary"
            >
              <IconButton className="outlined-button-icon"><FiLink2 /></IconButton>
              Copy link
            </Button>

            <Button
              onClick={() => onRefresh()}
              variant="contained"
              className="button"
              color="primary"
            >
              <IconButton className="contained-button-icon"><IoMdRefresh size={18} /></IconButton>
              Refresh
            </Button>
          </Grid>
        </Grid>
      </div>

      <Box fullWidth>
        <HashedTabs tabs={tabs} />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="The URL link of this task has been copied!"
        onClose={() => setCopySnackbarOpen(false)}
        open={copySnackbarOpen}
      />
    </>
  );
}

export default TaskDetails;
