import {
  FETCH_TASKS_START,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAIL,
  FETCH_TASK_START,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_FAIL,
  FETCH_LATEST_TASKS_START,
  FETCH_LATEST_TASKS_SUCCESS,
  FETCH_LATEST_TASKS_FAIL,
  FETCH_SDL_FOLDERS_START,
  FETCH_SDL_FOLDERS_SUCCESS,
  FETCH_SDL_FOLDERS_FAIL,
} from '../actionTypes';
import { parseErrorEntity, parseSdlFolderEntity, parseTaskEntity } from '../../core/parsers';

const initialState = {
  tasks: [],
  loading: false,
  details: {
    task: {},
    loading: false,
    errors: [],
  },
  latestTasks: {
    tasks: [],
    loading: false,
  },
  sdlFolders: {
    folders: [],
    loading: false,
  },
};

export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TASKS_START:
      return { ...state, loading: true };
    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload.map(parseTaskEntity),
        loading: false,
      };
    case FETCH_TASKS_FAIL:
      return {
        ...state,
        tasks: [],
        loading: false,
      };

    case FETCH_TASK_START:
      return {
        ...state,
        details: {
          task: {},
          loading: true,
          errors: [],
        },
      };
    case FETCH_TASK_SUCCESS:
      return {
        ...state,
        details: {
          task: action.payload ? parseTaskEntity(action.payload) : {},
          loading: false,
          errors: [],
        },
      };
    case FETCH_TASK_FAIL:
      return {
        ...state,
        details: {
          task: {},
          loading: false,
          errors: action.payload.map(parseErrorEntity),
        },
      };

    case FETCH_LATEST_TASKS_START:
      return {
        ...state,
        latestTasks: {
          tasks: [],
          loading: true,
        },
      };
    case FETCH_LATEST_TASKS_SUCCESS:
      return {
        ...state,
        latestTasks: {
          tasks: action.payload.map(parseTaskEntity),
          loading: false,
        },
      };
    case FETCH_LATEST_TASKS_FAIL:
      return {
        ...state,
        latestTasks: {
          tasks: [],
          loading: false,
        },
      };

    case FETCH_SDL_FOLDERS_START:
      return {
        ...state,
        sdlFolders: {
          folders: [],
          loading: true,
        },
      };
    case FETCH_SDL_FOLDERS_SUCCESS:
      return {
        ...state,
        sdlFolders: {
          folders: action.payload.map(parseSdlFolderEntity),
          loading: false,
        },
      };
    case FETCH_SDL_FOLDERS_FAIL:
      return {
        ...state,
        sdlFolders: {
          folders: [],
          loading: false,
        },
      };

    default:
      return state;
  }
}
