import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Grid,
  InputAdornment,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineSearch } from 'react-icons/hi';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { AiOutlineFolder } from 'react-icons/ai';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import PathBreadcrumbs from '../common/PathBreadcrumbs';
import { fetchSdlFolders } from '../../redux/actions/taskActions';
import { isInclude } from '../../core/services/commonService';
import Loader from '../common/Loader';
import { MAIN_UI_COLOR } from '../../config';

const useStyles = makeStyles(() => ({
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 150,
    minHeight: 150,
    height: 150,
  },
  notchedOutline: {
    border: '1px solid #bdbdbd',
  },
}));

function SdlFoldersDialog(props) {
  const {
    username,
    password,
    server,
    domain,
    title,
    open,
    onClose,
    onSubmit,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [path, setPath] = useState('');
  const [folderRef, setFolderRef] = useState('');
  const [pathNamesMap, setPathNamesMap] = useState({ '': 'Tridion Sdl Repository' });

  const [searchValue, setSearchValue] = useState('');

  const { folders, loading } = useSelector(state => state.task.sdlFolders);

  useEffect(() => {
    if (open) {
      setSearchValue('');
      dispatch(
        fetchSdlFolders(
          server,
          username,
          password,
          domain !== '' ? domain : undefined,
          folderRef,
        ),
      );
    }
  }, [dispatch, folderRef, open]);

  const onChangeFolderRef = folderEntity => {
    setPathNamesMap(Object.assign(pathNamesMap, { [folderEntity.folderRef]: folderEntity.name }));
    setFolderRef(folderEntity.folderRef);
    setPath(`${path}/${folderEntity.folderRef}`);
  };

  const onChangePath = pathValue => {
    setPath(pathValue);

    const splitedPath = pathValue.split('/');
    if (splitedPath.length > 0) setFolderRef(splitedPath.pop());
  };

  let objects = folders.sort((a, b) => a.name.localeCompare(b.name));
  if (searchValue !== '') objects = folders.filter(obj => isInclude(obj.name, searchValue));

  const handleSubmit = () => {
    onSubmit(folderRef);
    closeDialog();
  };

  function closeDialog() {
    onClose();
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <RyffineDialogTitle onClose={() => closeDialog()}>
        {title}
      </RyffineDialogTitle>

      <DialogContent style={{ padding: '10px 24px 0px 24px', background: '#F8F8F8' }}>
        <Grid className="mb-2" container>
          <PathBreadcrumbs
            itemsProp={{ style: { color: MAIN_UI_COLOR } }}
            onItemClick={value => onChangePath(value)}
            namesMap={pathNamesMap}
            path={path}
          />
        </Grid>
        <Grid
          container
          style={{
            marginBottom: '20px',
            borderRadius: '4px',
            background: 'white',
          }}
        >
          <Grid item xs={12}>
            <Box sx={{ boxShadow: 0 }}>
              <TextField
                onChange={event => {
                  setSearchValue(event.target.value);
                }}
                placeholder="Search a folder by name"
                InputProps={{
                  style: {
                    borderRadius: '4px 4px 0 0',
                    background: '#FFFFFF',
                  },
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <HiOutlineSearch style={{ color: 'grey' }} />
                    </InputAdornment>
                  ),
                  fullWidth: true,
                }}
                value={searchValue}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item xs={12} style={{ border: '1px solid rgba(51, 51, 51, 0.3)', borderTop: 0, borderRadius: '0 0 4px 4px' }}>
            {loading
                && (
                <Grid style={{ minHeight: 150, maxHeight: 150, height: 150 }}>
                  <Loader />
                </Grid>
                )}

            {!loading
                && (
                <List className={classes.list}>
                  {!loading && objects.map(obj => (
                    <ListItem key={`item-${obj.name}`}>
                      <Grid container onClick={() => onChangeFolderRef(obj)}>
                        <Grid item xs={11}>
                          <AiOutlineFolder style={{ marginRight: '5px' }} />
                          {obj.name}
                        </Grid>

                        <Grid container item xs={1} justifyContent="flex-end">
                          <IconButton style={{ fontSize: '14px', padding: 0 }}>
                            <MdOutlineArrowForwardIos />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
                )}

          </Grid>
        </Grid>
      </DialogContent>

      {/* <DialogContent>
        <Grid className="mt-3" container>
          <Grid item xs={6}>
            <SearchField
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              placeholder="Find objects by name"
            />
          </Grid>

          <Grid className="mt-3" item xs={12}>
            <RyffineTable
              rows={tableRows}
              columns={TABLE_COLUMNS}
              loading={loading}
              initSorting={{ field: 'name', order: 'asc' }}
              actionType={ACTION_TYPE_RADIO}
              onSelectionChange={handleChangeSelectionState}
              selectedRowIds={selectedObject ? [selectedObject] : []}
            />
          </Grid>
        </Grid>

        <Grid className="buttons-container my-4" container justifyContent="flex-end" />
      </DialogContent> */}

      <DialogActions style={{ padding: '10px 24px 24px 24px', background: '#F8F8F8' }}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              disabled={folderRef === ''}
              onClick={handleSubmit}
              variant="contained"
              className="button"
              color="primary"
            >
              Apply
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={() => closeDialog()}
              className="button"
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

SdlFoldersDialog.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  server: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SdlFoldersDialog;
